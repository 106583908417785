@import "./constants.scss";

* {
  box-sizing: border-box;
}

html, body, #root {
  padding: 0;
  margin: 0;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}

.app {
  background-color: $totalBlack;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
}